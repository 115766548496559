import { Drawer } from 'antd'
import clsx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import { isEmpty, isEqual, isNumber, omitBy } from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { job as jobAtom, user as userAtom } from 'src/atoms'
import useCandidateJobApplication from 'src/hooks/useCandidateJobApplication'
import { getAllJobs } from 'src/services/jobs'
import { IJob } from 'src/services/jobs/jobsv2'

import { JobDetailView } from '@/components/jobs/JobDetailView'
import CandidateJobApplicationModal from '@/components/Organisms/Candidate/JobApplication/CandidateJobApplicationModal'
import JobsSkeletonLoader from '@/components/Organisms/Candidate/Jobs/JobsSkeletonLoader'
import { EmptyState } from '@/components/shared/EmptyState'
import { ShouldRender } from '@/components/shared/misc'
import Pagination from '@/components/shared/misc/PaginationV2'
import { isLoggedIn, notify } from '@/utils/lib'
import { formatErrorObject } from '@/utils/static-helpers/formatError'
import { poweredByQureosDark } from '@/utils/static-helpers/images'
import { SourceNoData } from '@qureos/assets'
import { perPageJobsCount } from '@qureos/statics/jobs'
import { ApprenticeProfile, CorporateJobResponse, JobType } from '@qureos/types'

export interface JobsProps {
  // investigate later why this is not typed as IJob
  data: CorporateJobResponse[]
  metaData: { total: number }[]
}

interface JobFiltersProps {
  jobId: string
  pageNumber: number
  handlePageNumberChange: (pageNumber: number) => void
  handleJobIdChange: (jobId: string) => void
  selectedJob?: CorporateJobResponse
  setSelectedJob?: (data) => void
  setTotalJobs?: (el: number) => void
  filters?: {
    themeIds?: string[]
    location?: string
    'createdAt[gte]'?: string
    includePublicJobs?: boolean
    title?: string
  }
}

const PublicJobs: React.FC<JobFiltersProps> = ({
  jobId,
  pageNumber,
  handlePageNumberChange,
  handleJobIdChange,
  selectedJob,
  setSelectedJob,
  setTotalJobs,
  filters
}) => {
  const pageRoute = useRouter()
  const { t: Labels } = useTranslation('CorporateJobs')
  const [jobs, setJobs] = useState<JobsProps>(null)
  const [listLoading, setListLoading] = useState(true)
  const [totalPage, setTotalPage] = useState(1)
  const divRef = useRef<HTMLDivElement>()
  const [, setJob] = useRecoilState(jobAtom)
  const [user] = useRecoilState<ApprenticeProfile>(userAtom)
  const [showSlider, setShowSlider] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(0)
  const { modalProps, modalSettings, modalCallbacks } =
    useCandidateJobApplication({
      job: selectedJob as any as IJob,
      user: user
    })

  const fetchJobs = async () => {
    try {
      const offset = ((pageNumber || 1) - 1) * perPageJobsCount
      setListLoading(true)
      const response = await getAllJobs({
        ignoreAppliedJobs: false,
        limit: perPageJobsCount,
        offset: isNumber(offset) ? offset : 0,
        ...filters
      })
      if (response?.data) {
        setJobs(response)
        let selected = null

        if (response.data.length > 0) {
          setTotalJobs?.(response?.metaData?.[0]?.total)
          if (jobId) {
            selected = response.data.find(job => job._id === jobId)
            if (!selected) selected = response.data[0]
          } else selected = response.data[0]
        }
        setSelectedJob(selected)
        handleJobIdChange(selected?._id)
      } else {
        setSelectedJob(null)
        handleJobIdChange(null)
      }
    } catch (error) {
      const message = formatErrorObject(error)
      notify({ message: message, type: 'error' })
      setJobs(null)
      handleJobIdChange(null)
    } finally {
      setListLoading(false)
    }
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  const prevJobFilterObj = useRef(filters)
  const prevPageNumber = useRef(pageNumber)
  useEffect(() => {
    const themeIds = filters?.themeIds as string[]
    const publicJobs = filters?.includePublicJobs as boolean

    const updatedFilterObj = omitBy(
      {
        ...filters,
        themeIds: themeIds?.length > 0 ? themeIds : undefined,
        includePublicJobs: publicJobs === true ? undefined : publicJobs
      },
      v => v === undefined
    )

    if (
      !isEqual(prevJobFilterObj.current, filters) ||
      isEmpty(updatedFilterObj) ||
      prevPageNumber.current !== pageNumber
    ) {
      setSelectedJob(null)
      if (!listLoading) fetchJobs()
    }
    prevJobFilterObj.current = filters
    prevPageNumber.current = pageNumber
  }, [filters, pageNumber])

  useEffect(() => {
    if (selectedJob) {
      const divR = divRef.current
      if (divR) divR.scrollTop = 0
      setJob(selectedJob) // this will update jobs on jobAtom

      const selectedIndex = jobs?.data?.findIndex(
        job => job._id === selectedJob._id
      )
      if (selectedIndex !== -1 && typeof selectedIndex === 'number') {
        setScrollHeight(selectedIndex * 120)
      }
    }
  }, [selectedJob])

  const handleJobClick = (data: CorporateJobResponse) => {
    handleJobIdChange(data._id)
    setSelectedJob(data)
    setShowSlider(true)
  }

  const getLocation = jobData => {
    return jobData.locations?.[0]?.location ?? jobData.location
  }

  const getRemoteLocation = jobData => {
    if (
      jobData.workStyle === JobType.Remote ||
      jobData.workStyles?.includes(JobType.Remote)
    ) {
      const location = getLocation(jobData)
      return Labels('REMOTE') + (location ? ` | ${location}` : '')
    }
    return null
  }

  const getCreatedDuration = jobData => {
    return jobData.createdDateTime
      ? formatDistanceToNow(jobData.createdDateTime, { addSuffix: true })
      : null
  }

  const jobsCard = useMemo(() => {
    if (listLoading) {
      return <JobsSkeletonLoader />
    } else {
      const totalData = jobs?.metaData?.[0].total ?? 0
      const pageCount =
        Number(totalData) < perPageJobsCount
          ? 1
          : Math.ceil(Number(totalData) / perPageJobsCount)
      setTotalPage(pageCount)

      if (jobs?.data?.length) {
        return jobs?.data?.map((val, indx) => {
          const location = getLocation(val)
          const isRemoteLocation = getRemoteLocation(val)
          const locationCount = val.locations?.length
            ? val.locations?.length - 1
            : 0
          const createdDuration = getCreatedDuration(val)

          return (
            <a key={`${val}-${indx}`} onClick={() => handleJobClick(val)}>
              <qc-job-listing
                job-title={val.title}
                company-name={val.company?.name}
                company-logo={val?.company?.logo ?? poweredByQureosDark}
                fallback-logo={poweredByQureosDark}
                location={isRemoteLocation || location}
                location-count={locationCount}
                job-posted-duration={createdDuration}
                is-selected={jobId === val._id}
                current-index={indx}
              ></qc-job-listing>
            </a>
          )
        })
      }
    }
  }, [jobs, selectedJob, listLoading, jobId])

  if (!pageRoute?.asPath) {
    return null
  }

  const customSectionHeight =
    pageRoute?.asPath?.includes('/jobs/search/') && isLoggedIn()
      ? 'max-h-[80vh]'
      : 'max-h-[70vh]'

  if (isEmpty(jobs?.data)) {
    return (
      <div className="flex items-center justify-center min-h-[70vh]">
        {listLoading ? (
          <JobsSkeletonLoader />
        ) : (
          <EmptyState
            title={Labels('NO_JOBS_FOUND')}
            titleClass="font-bold text-xl text-gray-800"
            descriptionClass="text-gray-500 text-sm xl:px-20 text-center"
            image={<Image src={SourceNoData} width={100} height={100} />}
            description={
              !isEmpty(filters) ? Labels('NO_JOBS_CLEAR_FILTER') : ''
            }
          />
        )}
      </div>
    )
  }
  return (
    <div className="flex flex-row">
      <qc-aside
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        scroll-upto={scrollHeight}
        aside-class={clsx(
          'border h-full w-[90vw] md:w-96',
          customSectionHeight
        )}
      >
        <div slot="aside-items">{jobsCard}</div>
        <ShouldRender check={totalPage > 0}>
          <div slot="pagination-content" className="overflow-x-clip">
            <Pagination
              totalPage={totalPage}
              currentPage={pageNumber}
              clickEvent={handlePageNumberChange}
              isLoading={listLoading}
            />
          </div>
        </ShouldRender>
      </qc-aside>
      <qc-context
        ref={divRef}
        class={clsx(
          'w-full overflow-y-auto custom-scrollbar p-2 hidden md:block',
          customSectionHeight
        )}
      >
        <div slot="content-items">
          <ShouldRender check={!isEmpty(selectedJob) && !listLoading}>
            <JobDetailView job={selectedJob} onApply={modalCallbacks.onApply} />
            <div
              slot="fallback"
              className="flex items-center justify-center min-h-[60vh]"
            >
              <ShouldRender check={listLoading}>
                <qc-spinner />
                <div
                  slot="fallback"
                  className="flex items-center justify-center min-h-[60vh]"
                >
                  <EmptyState
                    title={Labels('NO_JOBS_SELECTED')}
                    titleClass="font-bold text-xl text-gray-800"
                    descriptionClass="text-gray-500 text-sm xl:px-20 text-center"
                    image={
                      <Image src={SourceNoData} width={100} height={100} />
                    }
                    description={Labels('SELECT_ANY_JOB')}
                  />
                </div>
              </ShouldRender>
            </div>
          </ShouldRender>
        </div>
      </qc-context>
      <div className="block p-5 overflow-y-auto custom-scrollbar md:hidden">
        <Drawer
          title={selectedJob?.title || ''}
          width="100%"
          className="md:invisible"
          onClose={() => setShowSlider(false)}
          visible={showSlider && !isEmpty(jobId)}
          destroyOnClose
        >
          <div className="w-full">
            <JobDetailView job={selectedJob} onApply={modalCallbacks.onApply} />
          </div>
        </Drawer>
      </div>
      <CandidateJobApplicationModal
        data={modalProps}
        settings={modalSettings}
        callbacks={modalCallbacks}
      />
    </div>
  )
}

export default PublicJobs
