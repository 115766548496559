import { Input, message, Upload, Divider } from 'antd'
import { FC } from 'react'

import { Button } from '@/components/shared/basic/Buttons/Button'
import { ShouldRender } from '@/components/shared/misc'
import { toUnix } from '@/utils/date-helper'
import { encodeS3Filename } from '@/utils/lib/encode-filename'
import { notify } from '@/utils/lib/notify'
import { getFileSize } from '@/utils/static-helpers/fileSizeConverter'
import { InboxOutlined } from '@ant-design/icons'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import useGcs from 'src/hooks/useGcs'

import { CustomForm, FieldType } from '../../CustomForm/CustomForm'
import Modal from '../../Modal/Modal'
import { CandidateJobApplicationProps } from './CandidateJobApplicationModalProps'

const { Dragger } = Upload

const CandidateJobApplicationModal: FC<CandidateJobApplicationProps> = ({
  data,
  settings,
  callbacks
}) => {
  const { uploadToGcs } = useGcs()
  const props = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    itemRender: null,
    customRequest: async req => {
      try {
        if (data.userId) {
          const finalFileName = encodeS3Filename(req.file.name.toString())
          const cloudPath = `job-submission/${data.jobId}/${data.userId}/${toUnix(new Date().toISOString())}_${finalFileName}`

          // @ts-ignore
          const uploadedUrl = await uploadToGcs(
            cloudPath,
            req.file,
            req.file.type
          )
          if (uploadedUrl) {
            req.onSuccess(
              {
                url: uploadedUrl,
                // @ts-ignore
                name: req.file.name
              },
              // @ts-ignore
              req.file
            )
          }
        }
      } catch (err) {
        console.log(err, 'caught')
        req.onError(err)
      }
    },
    onChange(info) {
      const { status } = info.file
      switch (status) {
        case 'done':
          callbacks.onCvUpload.onSuccess(info.file)
          message.success(`${info.file.name} file uploaded successfully.`)
          break
        case 'removed':
          callbacks.onCvUpload.onRemove()
          break
        case 'error':
          callbacks.onCvUpload.onError?.(info.file.name)
          message.error(`${info.file.name} file upload failed.`)
          break
      }
    }
  }

  return (
    <Modal
      style={{
        maxWidth: '600px'
      }}
      className="modal-wrapper"
      wrapClassName="custom-scrollbar"
      footer={null}
      title={data.title}
      onCancel={callbacks.onCancel}
      visible={settings.visible}
    >
      <ShouldRender check={data.screeningQuestions?.length > 0}>
        <div className="py-2 mb-4 font-semibold">Answer a few questions</div>
        {data.screeningQuestions?.map((screeningQ, i) => (
          <div key={`${screeningQ.question}-${i}`}>
            <div className="py-2 font-medium">Question {i + 1}</div>
            <div className="py-2">{screeningQ.question}</div>
            <Input
              className="mt-2 rounded border-[#D7DEE7]"
              placeholder="Answer"
              value={data.screeningAnswers[i]?.answer}
              onChange={ev => callbacks.onSetAnswer(i, ev.target.value)}
            />
          </div>
        ))}
      </ShouldRender>
      <ShouldRender check={data.screeningQuestionsV2?.length > 0}>
        <CustomForm
          formFields={data.screeningQuestionsV2?.map(q => ({
            label: q.question,
            type: q.answerType as unknown as FieldType,
            name: q.question,
            options: q.options || []
          }))}
          onChange={callbacks.onSetScreeningAnswersV2}
        />
      </ShouldRender>
      <hr className="my-5 border" />
      {/* only showing first (latest) resume for now */}
      <ShouldRender check={data.candidateResume}>
        <div>
          <p className="my-1 text-base font-medium">Uploaded Resume</p>
          <div className="flex items-center justify-between w-full gap-4 my-3">
            <div className="w-10 h-10 min-w-[40px] bg-[#2557ff25] rounded-lg flex justify-center items-center">
              <DocumentTextIcon className="w-6 fill-current h-7 text-qureosPrimary" />
            </div>
            <div className="flex flex-col justify-center w-full">
              <div className="flex justify-between">
                <p className="font-medium text-gray-700">
                  {data.candidateResume?.name || 'Resume'}
                </p>
              </div>
              {data.candidateResume?.formattedDate && (
                <span className="text-sm text-gray-400">
                  Uploaded on {data.candidateResume?.formattedDate}
                </span>
              )}
            </div>
          </div>
        </div>
      </ShouldRender>
      <Divider>Or</Divider>
      <p className="my-1 text-base font-semibold">Upload New Resume</p>
      <Dragger
        key={settings.key}
        accept=".pdf"
        beforeUpload={file => {
          if (file.size > settings.maxFileSize) {
            notify({
              message: `Maximum allowed file size is ${getFileSize(settings.maxFileSize)} MB`,
              type: 'error'
            })
            return Upload.LIST_IGNORE
          }
          return true
        }}
        className="relative flex flex-col w-full h-48"
        {...props}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="p-4 ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Upload your CV (PDF file up to 5 MB)</p>
      </Dragger>
      <small className="block my-3 text-sm text-gray-600">
        Submitting a new resume on this application will update your profile
        data.
      </small>
      <div className="flex justify-end w-full py-4">
        <div className="flex justify-end gap-4 w-min">
          <Button
            onClick={callbacks.onCancel}
            disabled={settings.loading}
            rounded="lg"
            className="font-semibold"
            theme="gray"
          >
            Cancel
          </Button>
          <Button
            onClick={callbacks.onApply}
            loading={settings.loading}
            rounded="lg"
            className="font-semibold"
            theme="normal"
            disabled={!data.candidateResume || data.hasPendingQuestions}
          >
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CandidateJobApplicationModal
