import { message } from 'antd'
import { nanoid } from 'nanoid'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { updateApprenticesResume } from 'src/services/apprentices/professionalDetails'
import { startApply, triggerSubmissionEmail } from 'src/services/jobs/jobs'
import { IJob, IScreeningQuestionAnswerV2 } from 'src/services/jobs/jobsv2'
import { captureEvent } from 'src/services/user-analytics'
import { ApprenticeProfile, CV } from 'src/types/apprentice'
import { SegmentEventType } from 'src/types/segment'
import {
  UserAnalyticsEventName,
  UserAnalyticsEventType
} from 'src/types/userAnalytics'

import { isLoggedIn, loginRedirect } from '@/utils/lib/auth'
import { segmentTrackCallback } from '@/utils/segment'
import { getBytesFileSize } from '@/utils/static-helpers/fileSizeConverter'
import { useRecoilState } from 'recoil'
import { updateProfile as updateProfileAtom } from 'src/atoms/user'

interface Props {
  job: IJob
  user: ApprenticeProfile
}

const useCandidateJobApplication = ({ job, user }: Props) => {
  const [screeningAnswers, setScreeningAnswers] = useState([])
  const [screeningAnswersV2, setScreeningAnswersV2] = useState([])
  const [loading, setLoading] = useState(false)
  const [parseCV, setParseCV] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalKey, setModalKey] = useState<string>(nanoid())
  const [, setUpdate] = useRecoilState<boolean>(updateProfileAtom)

  const latestCv: CV | undefined = useMemo(() => {
    const userCv = user?.professionalDetails?.resumes?.[0]
    return userCv
      ? {
          link: userCv.link,
          name: userCv.title,
          size: userCv.size,
          isPrimary: userCv.isPrimary,
          uploadedAt: userCv.uploadedAt
        }
      : undefined
  }, [user])

  useEffect(() => {
    setScreeningAnswers(
      job?.screeningQuestions?.map(({ question: text }) => ({
        text,
        answer: ''
      })) || []
    )
    setScreeningAnswersV2(
      job?.screeningQuestionsV2?.map(({ question }) => ({
        question,
        answer: ''
      })) || []
    )
  }, [job?.screeningQuestions, job?.screeningQuestionsV2])

  const router = useRouter()

  const hasPendingQuestions = useMemo(
    () =>
      screeningAnswers.some(sq => !sq.answer) ||
      (screeningAnswersV2 && screeningAnswersV2.some(sq => !sq.answer)),
    [screeningAnswers, screeningAnswersV2]
  )

  /** SEGMENT TRACKING */
  const trackPublicJobRedirect = (jobUrl: string) => {
    segmentTrackCallback(SegmentEventType.PUBLIC_JOB_REDIRECT, {
      job,
      jobUrl,
      userId: user._id,
      userEmail: user.email
    })
    captureEvent({
      _id: job._id,
      name: UserAnalyticsEventName.PUBLIC_JOB,
      type: UserAnalyticsEventType.REDIRECT
    })
  }

  const trackJobApplication = useCallback(response => {
    segmentTrackCallback(SegmentEventType.JOB_APPLY, {
      jobTitle: response.job.title,
      jobId: response.job._id,
      userName:
        response.application.apprentice.firstName +
        ' ' +
        response.application.apprentice.lastName,
      userId: response.application.apprentice?._id,
      userEmail: response.application.apprentice?.email,
      applicationId: response.application._id,
      companyName: response.job.company.name,
      companyId: response.job.company._id
    })
  }, [])

  /** END - SEGMENT TRACKING */

  const updateCvLink = async cv => {
    await updateApprenticesResume({
      link: cv.url,
      title: cv.name,
      isPrimary: true
    })
    // trigger recoil update
    setUpdate(true)
  }

  const applyToInternalJob = async () => {
    try {
      if (!latestCv) {
        message.error(`You need to upload your CV ${typeof latestCv}`)
      } else {
        setLoading(true)
        await startApply({
          id: job._id,
          cv: latestCv?.link,
          screeningQuestionsAnswers: screeningAnswers,
          parseCV,
          screeningQuestionAnswersV2: toScreeningAnswersV2(screeningAnswersV2)
        }).then(resp => {
          const { data } = resp
          if (data && job) {
            trackJobApplication({ application: data, job })
          }
          message.success(`You have now applied to ${job.title}`)
          setLoading(false)
          // there's a flaw in the UX. if user is on the job list page,
          // they will be redirected to the thank you page
          // and had to manually go back to the job list page
          router.push(
            `/jobs/${job._id}/thank-you?isPublic=${job?.isPublic ? true : false}`
          )
        })
        if (latestCv && parseCV) updateCvLink([latestCv])
      }
    } catch (error) {
      console.log(error)
      message.error(error.response.data.message)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const applyToJob = async () => {
    if (isLoggedIn()) {
      if (job?.isPublic || job?.isExternal) {
        /* only need to check for apply link in case of public jobs  */
        const url = job?.applyLink || job?.linkedinLink
        if (url) {
          trackPublicJobRedirect(url)
          window.open(url, '_blank')
          router.push(`/jobs/${job?._id}/thank-you?isPublic=true`)
          await triggerSubmissionEmail(job._id)
        } else {
          message.error('Not able to fetch job url')
        }
      } else {
        if (!latestCv || hasPendingQuestions) {
          setModalVisible(true)
        } else {
          await applyToInternalJob()
        }
      }
    } else {
      loginRedirect('set', router.asPath)
      message.error('You need to sign up or sign in to apply')
      router.push({ pathname: '/', query: router.query })
    }
  }

  const toScreeningAnswersV2 = (
    answers: any[]
  ): IScreeningQuestionAnswerV2[] => {
    const screeningQuestions = job?.screeningQuestionsV2

    const mappedData = answers.map((answer, index) => ({
      text: answer.question,
      answer: answer.answer,
      idealAnswer: screeningQuestions[index]?.idealAnswer
    }))
    return mappedData
  }

  const resetCvSelection = () => {
    setParseCV(false)
    setModalKey(nanoid())
  }

  const onSetAnswer = useCallback((index: number, answer: string) => {
    setScreeningAnswers(prev =>
      prev.map((sq, i) => (i === index ? { ...sq, answer } : sq))
    )
  }, [])

  return {
    modalProps: {
      title: `Apply to ${job?.company?.name}`,
      userId: user?._id,
      jobId: job?._id,
      candidateResume: latestCv,
      hasPendingQuestions,
      screeningQuestions: job?.screeningQuestions,
      screeningQuestionsV2: job?.screeningQuestionsV2,
      screeningAnswers,
      screeningAnswersV2
    },
    modalSettings: {
      loading,
      key: modalKey,
      maxFileSize: getBytesFileSize(5),
      visible: modalVisible
    },
    modalCallbacks: {
      onCancel: () => {
        setModalVisible(false)
        setModalKey(nanoid())
      },
      onApply: applyToJob,
      onSetScreeningAnswersV2: setScreeningAnswersV2,
      onSetAnswer,
      onCvUpload: {
        onSuccess: (file: any) => {
          setParseCV(true)
          updateCvLink(file.response)
        },
        onRemove: () => resetCvSelection()
      }
    }
  }
}

export default useCandidateJobApplication
