import {
  format,
  formatDistance,
  getUnixTime,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from 'date-fns'
import { isEmpty } from 'lodash'
import moment from 'moment'

interface Iyears {
  startAt: Date
  endAt?: Date
}

/**
 * Converts a date string to Unix timestamp.
 *
 * @param dateString - The date string to convert.
 * @returns The Unix timestamp.
 */
const toUnix = (dateString: string) => {
  const date = new Date(dateString)

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date')
  }

  return getUnixTime(date)
}

export enum PastDateEnum {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day'
}
export interface PastDateInterface {
  date: Date
  type: PastDateEnum
  value: number
  format?: string
}
/**
 * Calculates the past date based on the provided parameters.
 *
 * @param params - The parameters for calculating the past date.
 * @returns The calculated past date.
 */
const getPastDate = (params: PastDateInterface) => {
  const { date, type, value } = params
  const { YEAR, MONTH, WEEK, DAY } = PastDateEnum

  switch (type) {
    case YEAR: {
      const subYear = subYears(date, value)
      return params.format ? format(subYear, params.format) : subYear
    }
    case MONTH: {
      const subMonth = subMonths(date, value)
      return params.format ? format(subMonth, params.format) : subMonth
    }
    case WEEK: {
      const subWeek = subWeeks(date, value)
      return params.format ? format(subWeek, params.format) : subWeek
    }
    case DAY: {
      const subDay = subDays(date, value)
      return params.format ? format(subDay, params.format) : subDay
    }
    default:
      throw new Error('Invalid type')
  }
}

export interface DistanceInterface {
  startDate: Date
  endDate?: Date
}

/**
 * Calculates the distance between two dates.
 *
 * @param {DistanceInterface} params - The parameters for calculating the distance.
 * @param {Date} params.startDate - The start date.
 * @param {Date} [params.endDate] - The end date. If not provided, the function will return the start date.
 * @returns {string} - The formatted distance between the start and end dates, or the start date if the end date is not provided.
 */
const getDistance = (params: DistanceInterface): string => {
  const { startDate, endDate } = params

  return formatDistance(startDate, endDate)
}

const getNextWorkDay = (dateString: string) => {
  while ([0, 6].includes(moment(dateString).day())) {
    // since LinkedIn actions are not done on weekends, we need to show the next working day
    dateString = moment(dateString)
      .add(1, 'day')
      .startOf('day')
      .format('DD MMM, YYYY')
  }

  return dateString
}

const formatDate = (date: Date, newFormat: string) => format(date, newFormat)

/**
 * Formats a date to a localized format similar to moment's 'lll' format
 * Example output: "Feb 14, 2017 3:25 PM"
 *
 * @param date - The date to format. Can be a Date object or a date string
 * @returns The formatted date string
 */
const formatToLocalizedDateTime = (date: Date | string): string => {
  if (isEmpty(date)) {
    return ''
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date
  return format(dateObj, 'MMM d, yyyy h:mm a')
}

const getTotalYearsAndMonths = (years: Iyears[]) => {
  let totalMonths = 0

  years?.forEach(exp => {
    const startDate = new Date(exp.startAt)
    const endDate = exp.endAt ? new Date(exp.endAt) : new Date()

    const diffInMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth())
    totalMonths += diffInMonths
  })

  const totalYears = Math.floor(totalMonths / 12)
  const remainingMonths = totalMonths % 12
  return {
    totalYears,
    remainingMonths,
    totalMonths
  }
}

export {
  getDistance,
  getPastDate,
  getNextWorkDay,
  getTotalYearsAndMonths,
  formatDate,
  formatToLocalizedDateTime,
  toUnix
}
