import {
  Button as ButtonV2,
  ButtonVariants
} from '@/components/shared/basic/Buttons/V2'
import { ShouldRender } from '@/components/shared/misc'
import { formatErrorObject } from '@/utils/static-helpers/formatError'
import { chevronLeftBlack } from '@qureos/assets'
import { isLoggedIn, notify } from '@qureos/lib'
import { CorporateJobResponse, JobStatus } from '@qureos/types'
import { Alert } from 'antd'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  checkIsApplied,
  declineRecommendation
} from 'src/services/recommendations'
import { JobDetailViewDescription } from './JobDetailViewDescription'
import { JobDetailViewFooter } from './JobDetailViewFooter'
import { JobViewDetailCover } from './JobViewDetailCover'
import { JobViewDetailInfo } from './JobViewDetailInfo'
import Link from 'next/link'
import { getSeoJobPostingSchema } from '@/utils/seo/job'

type JobDetailViewProps = {
  job: CorporateJobResponse
  onApply?: () => Promise<void>
  isAdmin?: boolean
  showBack?: boolean
  showApply?: boolean
}

export const JobDetailView: React.FC<JobDetailViewProps> = ({
  job,
  onApply,
  showBack = false,
  isAdmin = false,
  showApply = true
}) => {
  const router = useRouter()
  const { decline, declined } = router.query
  const [visible, setVisible] = useState<boolean>(Boolean(decline) || false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isApplied, setIsApplied] = useState<boolean>(false)
  const [isDeclined, setIsDeclined] = useState<boolean>(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const CompanyDescription = () => (
    <JobViewDetailCover
      companyHandle={job?.company?.handle}
      companyName={job?.company?.name}
      jobTitle={job?.title}
      brief={job?.company?.brief}
      companyLogo={job?.company?.logo}
      coverPhoto={job?.company?.coverPhoto}
      location={job?.company?.location?.[0]}
      website={job?.company?.website}
      jobLink={
        showBack ? undefined : `${process.env.APP_URL}/jobs/${job?.slug}`
      }
    />
  )

  const handleCaptcha = async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return ''
    }
    return await executeRecaptcha()
  }

  const handleClose = () => {
    setVisible(false)
    router.pathname = router.asPath.split('?')?.[0]
    router.query = {
      declined: decline
    }
    if (decline) delete router.query.decline
    router.push(router)
  }

  const handleDeclineRecommendation = async () => {
    try {
      setIsLoading(true)
      const captcha = await handleCaptcha()
      const body = {
        captcha,
        decline: String(decline)
      }
      await declineRecommendation(body)
      handleClose()
      notify({ message: 'Recommendation declined!', type: 'success' })
    } catch (error) {
      const message = formatErrorObject(error)
      notify({ message: message, type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchData = async () => {
    try {
      const result = await checkIsApplied((decline || declined) as string)
      if (result?.appliedAt) setIsApplied(true)
      if (result?.declinedAt) setIsDeclined(true)
    } catch (error) {
      const message = formatErrorObject(error)
      notify({ message: message, type: 'error' })
    }
  }

  const getBackLink = () => {
    const isExternal = job?.isExternal || job?.isPublic

    switch (true) {
      case isLoggedIn():
        return '/jobs?currentTab=applied-jobs'
      case isExternal:
        return '/jobs/all'
      default:
        return '/jobs'
    }
  }

  useEffect(() => {
    if (decline || declined) fetchData()
  }, [decline, declined])

  return (
    <div className="px-8 pt-6 pb-8 mx-auto bg-white rounded-lg shadow-lg font-inter">
      <Head>
        {job?.status === JobStatus.Closed ? (
          <meta name="robots" content="noindex" />
        ) : (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: getSeoJobPostingSchema(job)
            }}
          />
        )}
      </Head>
      {visible && !isApplied && (
        <div className="my-4">
          <Alert
            message={
              <p>
                <b className="font-medium">Confirmation Required:</b> Are you
                certain about declining this Job opportunity?
              </p>
            }
            type="success"
            action={
              <div className="flex items-center gap-x-3">
                <ButtonV2
                  size={3}
                  loading={isLoading}
                  variant={ButtonVariants.PRIMARY}
                  className="bg-qureosPrimary"
                  onClick={handleDeclineRecommendation}
                >
                  <span className="whitespace-nowrap">Decline</span>
                </ButtonV2>
                <span
                  onClick={handleClose}
                  className="font-semibold text-gray-400 hover:text-gray-700 hover:cursor-pointer"
                >
                  X
                </span>
              </div>
            }
          />
        </div>
      )}
      <ShouldRender check={showBack}>
        <Link passHref prefetch href={getBackLink()}>
          <a
            data-testid="job-detail-back-btn"
            className="flex mb-5 bg-transparent border-0 cursor-pointer"
          >
            <div className="flex items-center justify-center w-5 h-5 overflow-hidden">
              <img src={chevronLeftBlack} alt="back" className="w-4 h-4" />
            </div>
            <div className="font-medium text-sm leading-5 text-gray-500 ml-[13px]">
              Back
            </div>
          </a>
        </Link>
      </ShouldRender>
      <div className="hidden sm:block">
        <CompanyDescription />
      </div>
      <JobViewDetailInfo
        isEnterprise={
          job?.company?.isEnterprise || !!job?.company?.parentCompany
        }
        location={job?.location}
        locations={job?.locations}
        parentCompanyName={job?.company?.businessName}
        salary={job?.payRange}
        showPayToApprentices={!job?.payRange?.isHidden}
        workStyles={job?.workStyles}
        themeName={job?.theme?.label}
        type={job?.contractType}
        applyNowHandler={onApply}
        isExpired={job?.status === JobStatus.Closed}
        isPublic={job?.isExternal || job?.isPublic}
        isAdmin={isAdmin}
        hideApplyCta={isDeclined || !showApply}
      />
      <JobDetailViewDescription description={job?.description} />
      <div className="sm:hidden">
        <CompanyDescription />
      </div>
      {job?.company?.parentCompany?.toLowerCase().match(/tumoohi/i) && (
        <JobDetailViewFooter />
      )}
    </div>
  )
}
