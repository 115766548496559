import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd'
import clsx from 'clsx'

// Extend the Antd Modal props to include children
export interface CustomModalProps extends AntdModalProps {
  children: React.ReactNode
}

const Modal = ({ children, className, ...restProps }: CustomModalProps) => (
  <AntdModal {...restProps} className={clsx(className)}>
    {children}
  </AntdModal>
)

export default Modal
