import { InboxOutlined } from '@ant-design/icons'
import { Checkbox, DatePicker, Select, Upload, message } from 'antd'
import { add } from 'lodash'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useGcs from 'src/hooks/useGcs'

const { Dragger } = Upload

export enum FieldType {
  NUMBER = 'number',
  SINGLE_SELECT = 'singleSelect',
  EMAIL = 'email',
  DATE = 'date',
  MULTIPLE_SELECT = 'multipleSelect',
  FILE_UPLOAD = 'fileUpload',
  FREE_TEXT = 'freeText'
}

interface FormItem {
  label: string
  options: string[]
  type: FieldType
}

interface CustomFormData {
  question: string
  answer: string
}

interface CustomFormProps {
  formFields: FormItem[]
  onChange: (values: CustomFormData[]) => void
}

const isValidEmail = (email: string) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return emailRegex.test(email)
}

export const CustomForm: React.FC<CustomFormProps> = ({
  formFields,
  onChange
}) => {
  const { register, watch, control } = useForm()
  const { uploadToGcs } = useGcs()

  useEffect(() => {
    const subscription = watch(value => {
      const formDataArray = formFields.map((field, index) => {
        const answer = value[`question_${index}`] || ''

        if (field.type === FieldType.EMAIL) {
          return {
            question: field.label,
            answer: isValidEmail(answer as string) ? answer : ''
          }
        }

        return {
          question: field.label,
          answer: answer as string
        }
      })
      onChange(formDataArray)
    })
    return () => subscription.unsubscribe()
  }, [watch, onChange, formFields])

  const renderQuestionField = (formItems: FormItem, index: number) => {
    const fieldName = `question_${index}`
    const label = `Q${add(index, 1)}. ${formItems.label}`

    switch (formItems.type) {
      case FieldType.NUMBER:
        return (
          <div className="form-item">
            <label className="m-0 mb-2">{label}</label>
            <input
              type="number"
              className="py-1 border-gray-300 rounded-lg"
              placeholder="Enter a number"
              {...register(fieldName, { required: true })}
            />
          </div>
        )

      case FieldType.SINGLE_SELECT:
        return (
          <div className="form-item">
            <label className="m-0 mb-2">{label}</label>
            <Controller
              name={fieldName}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} className="w-full py-1 rounded-lg">
                  {formItems.options.map(option => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </div>
        )

      case FieldType.EMAIL:
        return (
          <div className="form-item">
            <label className="m-0 mb-2">{label}</label>
            <input
              type="email"
              className="w-full py-1 border-gray-300 rounded-lg"
              placeholder="Example: email@example.com"
              {...register(fieldName)}
            />
          </div>
        )

      case FieldType.DATE:
        return (
          <div className="form-item">
            <label className="m-0 mb-2">{label}</label>
            <Controller
              name={fieldName}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker {...field} className="w-full py-1 rounded-lg" />
              )}
            />
          </div>
        )

      case FieldType.MULTIPLE_SELECT:
        return (
          <div className="form-item custom-checkbox-group">
            <label className="m-0 mb-2">{label}</label>
            <Controller
              name={fieldName}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Checkbox.Group
                  value={value || []}
                  onChange={onChange}
                  className="flex flex-col gap-2"
                >
                  {formItems.options.map(option => (
                    <Checkbox key={option} value={option}>
                      {option}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              )}
            />
          </div>
        )

      case FieldType.FILE_UPLOAD:
        return (
          <div className="form-item">
            <label className="m-0 mb-2">{label}</label>
            <Controller
              name={fieldName}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Dragger
                  maxCount={10}
                  beforeUpload={file => {
                    const isLt5M = file.size / 1024 / 1024 < 5
                    if (!isLt5M) {
                      message.error('File must be smaller than 5MB!')
                      return Upload.LIST_IGNORE
                    }
                    return true // Changed to true to allow upload to proceed
                  }}
                  onChange={info => {
                    const { file } = info
                    if (file.status === 'done') {
                      const path = `forms/${Date.now()}_${file.name}`
                      uploadToGcs(path, file.originFileObj as File, 'other')
                        .then(uploadedPath => {
                          onChange(uploadedPath)
                        })
                        .catch(error => {
                          message.error('Upload failed')
                          console.error(error)
                        })
                    }
                  }}
                  customRequest={({ file, onSuccess, onError }) => {
                    try {
                      onSuccess?.('ok')
                    } catch (err) {
                      console.error('customRequest error', err)
                      onError?.(err as Error)
                    }
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Upload file up to 5 MB</p>
                </Dragger>
              )}
            />
          </div>
        )

      case FieldType.FREE_TEXT:
        return (
          <div className="form-item">
            <label className="m-0 mb-2">{label}</label>
            <textarea
              placeholder="Type your answer here..."
              className="min-h-[80px] rounded-lg border-gray-300 py-1 bg-white"
              {...register(fieldName, { required: true })}
            />
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div className="w-full space-y-5">
      {formFields.map((formItem, index) => (
        <div key={index} className="bg-white">
          {renderQuestionField(formItem, index)}
        </div>
      ))}
    </div>
  )
}
