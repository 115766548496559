const useCommonCtas = () => {
  // planning to include support functions here in the future if possible
  const handleTalkToSales = () => {
    // this line is somewhat a hack
    // issue is, as soon as calendly is closed. body overflow is set to hidden
    document.body.removeAttribute('style')
    // @ts-ignore
    window?.Calendly?.initPopupWidget({
      url: 'https://calendly.com/alex-qureos/iris-demo-20-min?primary_color=0f9f6e'
    })
  }

  const isCalendlyEvent = (e: any) => {
    return e.data.event && e.data.event.indexOf('calendly') === 0
  }

  const handleBoostJobCalendly = (callback?: () => void) => {
    // this line is somewhat a hack
    // issue is, as soon as calendly is closed. body overflow is set to hidden
    // document.body.removeAttribute('style')
    window.addEventListener('message', function (e) {
      if (isCalendlyEvent(e)) {
        const eventName = e.data.event
        switch (eventName) {
          case 'event_scheduled':
            callback?.()
            window.removeEventListener('message', null)
            break
        }
      }
    })
    // @ts-ignore
    window?.Calendly?.initPopupWidget({
      url: 'https://calendly.com/umer-qureos/30min?primary_color=0f9f6e'
    })
  }

  return {
    handleTalkToSales,
    handleBoostJobCalendly
  }
}

export default useCommonCtas
